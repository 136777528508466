.page {
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 5%;
}

.page h3 {
  font-family: "Inter";
  font-weight: 600;
  padding: 0px;
  margin: 0px;
  font-size: 30px;
}

.page h1 {
  font-family: "Inter";
  font-weight: 600;
  font-size: 70px;
  padding: 0px;
  margin: 0px;
}

.page p {
  font-family: "Inter";
  font-weight: 300;
  font-size: 15px;
  opacity: 50%;
}

.page img {
  width: 60%;
}

.overflow {
  overflow: hidden;
}

.hoverAnimation {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.hoverAnimation:hover {
  opacity: 0.93;
}

@media (max-width: 1536px) {
  .page {
    padding-top: 2%;
  }

}

@media (max-width: 899px) {
  .page {
    padding-left: 3em;
    padding-right: 3em;
  }

  .page h3 {
    font-size: 25px;
  }
}

@media (max-width: 599px) {
  .page {
    padding-left: 0em;
    padding-right: 0em;
    padding-top: 10%;
  }

  .page h1 {
    font-size: 50px;
  }

  .page img {
    width: 90%;
  }
}
