.page {
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 2%;
  padding-bottom: 1em;
}

.page p {
  font-family: "Inter";
  font-weight: 300;
  font-size: 16px;
  opacity: 80%;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.page h4 {
  font-family: "Inter";
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}

.page img {
  filter: brightness(0) invert(1);
  transition: filter 0.1s ease-in-out;
}

.page img:hover {
  filter: brightness(1);
  transition: filter 0.1s ease-in-out;
}

.page hr {
  margin-bottom: 7vh;
}

.sectionBreak {
  padding-top: 10vh;
}

.ov {
  overflow: hidden;
}

@media (max-width: 1535px) {
  .sectionBreak {
    padding-top: 5vh;
  }
}

@media (max-width: 899px) {
  .page {
    padding-left: 0em;
    padding-right: 0em;
  }

  .page p {
    width: 85%;
  }

}

@media (max-width: 599px) {
  .page {
    padding-bottom: 2em;
  }
  .page p {
    font-size: 16px;
  }
  .sectionBreak {
    padding-top: 5vh;
  }
}