.particlesOut {
    opacity: 0;
    transition: opacity 2.5s;
}

.particlesIn {
    opacity: 1;
    transition: opacity 2.5s;
}

@media (max-width: 900px) {
    .particlesIn {
        opacity: 0;
        transition: opacity 0.5s;
    }
}
