.page {
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 2%;
  padding-bottom: 1em;
}

.page h4 {
  font-family: "Nunito";
  font-weight: 900;
  font-size: 16px;
  padding: 0;
  margin-bottom: 0.4em;
}

.page hr {
  margin-bottom: 1em;
}

.page img {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1vh;
}

.page a {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.page a:hover {
  opacity: 0.8;
}

.ov {
  overflow: hidden;
}

.form {
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 899px) {
  .page {
    padding-left: 0em;
    padding-right: 0em;
  }
  .form {
    width: 90%;
  }
}

@media (max-width: 599px) {
  .page {
    padding-bottom: 2em;
  }
}
