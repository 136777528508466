.page {
  padding-top: 2%;
  text-align: center;
}

.page h4 {
  font-family: "Nunito";
  color: white;
  font-weight: 900;
  font-size: 17px;
}

.page p {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
}

.page hr {
  margin-bottom: 6vh;
}

.ov {
  overflow: hidden;
}

