.bar {
  box-shadow: none;
}

.img {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  width: 25px;
  padding-right: 2.5em;
}

.img:hover {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.mobileLogo {
  width: 25px;
}

@media (max-width: 899px) {
  .img {
    padding-right: 0em;
  }
}

@media (max-width: 399px) {
  .img {
    padding-right: 0.5em;
  }
}
