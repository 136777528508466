.page h3 {
  font-family: "Inter";
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 0;
}

.page hr {
  width: 30%;
  height: 4px;
  background-color: white;
  border-radius: 10px;
}

@media (max-width: 599px) {
  .page hr {
    width: 60%;
  }
}
