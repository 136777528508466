.App {
  font-family: 'Nunito';
  min-height: 100vh;
  overflow: hidden;
}

.themeDark {
  background-color: #1C1C1C;
  color: white;
}

.themeLight {
  background: url('images/pattern_background.png'), #EAECF1;
  color: black;
}

/* .App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

